function GalleryAdmin() {
    this.page = -1;
    this.count = 8;
    this.maxPreviews = 8;
    this.totalGalleries = 0;
    this.totalPages = 0;
    this.filter = false;
    
    this.zoom = false;

    this.prev = ".admin_galleries_action_prev";
    this.next = ".admin_galleries_action_next";
    this.search = "#galleries_search";

    var that = this;

    $(this.search).on("keydown",function(e) {
        if (e.which == 13) {
            that.filter = $(this).val();
            if (that.filter == "") {
                that.filter = false;
            }
            that.page = 0;
            that.navigate(0, true);
        }
    });
}

GalleryAdmin.prototype = {
    constructor: GalleryAdmin,
    navigate: function(page, filtered = false) {
        var that = this;
        var offset = page * this.count;

        if (this.page != page || filtered) {
            var filter = "";
            if (this.filter != false) {
                filter = "?s="+encodeURIComponent(this.filter);
            }
            window.history.pushState({page: page}, "Folder: "+page, "admin/galleries/p/"+page+filter);
            window.onpopstate = function(event) {
                if (event != null && event.state != null && event.state.hasOwnProperty("page")) {
                    that.page = event.state.page;
                    that.navigate(that.page);                    
                } else {
                    window.history.back();
                }
            }
        }

        this.page = page;
        this.display(this.count, offset, this.filter, function(result) {
            var galleries = result.galleries;
            that.totalGalleries = result.count;            
            that.totalPages = Math.max(1, Math.ceil(that.totalGalleries / that.count));
            if (that.page >= that.totalPages) {
                that.navigate(that.totalPages - 1);
                return;
            }

            $("#galleries_list").html("");
            for (var i = 0; i < galleries.length; ++i) {
                $("#galleries_list").append(
                    that.makeGalleryDiv(galleries[i])
                );
            }
            
            // Pagination
            var pages = [];
            for (var i = 0; i < that.totalPages; ++i) {
                pages.push(`
                    <li class="admin_galleries_action_page waves-effect" data-page="${i}"><a>${i+1}</a></li>
                `);
            }
                        
            $(".pagination").html(`
                <li class="${that.prev.replace('.', '')} pointer"><a><i class="material-icons">chevron_left</i></a></li>
                ${pages.join("\n")}                
                <li class="${that.next.replace('.', '')} pointer"><a><i class="material-icons">chevron_right</i></a></li>
            `);
                        
            $('.admin_galleries_action_page[data-page="' + that.page + '"]').removeClass("waves-effect").addClass("active");
            
            if (that.hasPrev()) {
                $(that.prev).removeClass("disabled");
            } else {
                $(that.prev).addClass("disabled");
            }

            if (that.hasNext()) {
                $(that.next).removeClass("disabled");
            } else {
                $(that.next).addClass("disabled");
            }
        });
    },
    hasPrev: function() {
        return this.page > 0;
    },
    hasNext: function() {
        return this.page < this.totalPages - 1;
    },
    create: function(title, status, callback) {
        $.ajax({
            url: "admin/galleries/api/create",
            method: "POST",
            data: {
                "title": title,
                "status": status
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    edit: function(gallery, name, pictures, callback) {
        $.ajax({
            url: "admin/galleries/api/edit",
            method: "POST",
            data: {
                "title": name,
                "pictures": pictures,
                "id": gallery
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    load: function(id, callback) {
        $.ajax({
            url: "admin/galleries/api/get",
            method: "POST",
            data: {
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    deleteGallery: function(id, callback) {
        $.ajax({
            url: "admin/galleries/api/delete",
            method: "POST",
            data: {
                "id": id
            },
            success: function success(response) {
                callback(true);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    addElements: function(id, elements, callback) {
        $.ajax({
            url: "admin/galleries/api/multiadd",
            method: "POST",
            data: {
                "elements": elements,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    display: function(count, offset, filter, callback) {
        $.ajax({
            url: "admin/galleries/api/list",
            method: "POST",
            data: {
                "sort": "date",
                "direction": "DESC",
                "offset": offset,
                "count": count,
                "loadPictures": true,
                "filter": filter
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    makeGalleryDiv: function(gallery) {
        var images = "";
        if (gallery.picturecount == 0) {
            images = `
                <div class="galleries_gallery_preview_counter">
                    0
                </div>
            `;
        } else {
            var max = Math.min(gallery.picturecount, this.maxPreviews);
            for (var i = 0; i < max; ++i) {
                var img = gallery.pictures[i];
                images += `
                    <img src="${img.thumbnail}" />
                `;
            }

            if (gallery.picturecount > this.maxPreviews) {
                images += `
                <div class="galleries_gallery_preview_counter circle blue white-text">
                    +${gallery.picturecount - this.maxPreviews}
                </div>
            `;
            }
        }


        return `
            <div class="galleries_gallery card" id="gallery_${gallery.id}" gallery="${gallery.id}" name="${gallery.title}">
                <div class="galleries_gallery_title card-content blue white-text flex-container flex-row">
                    <div class="galleries_gallery_title_text flex-1">#${gallery.id}: ${gallery.title}</div>
                    <div class="galleries_actions">
                        <i class="galleries_action_edit material-icons" gallery="${gallery.id}">edit</i>
                        <i class="galleries_action_delete material-icons" gallery="${gallery.id}">delete</i>
                    </div>
                </div>
                <div class="galleries_gallery_preview card-content">
                    ${images}
                </div>
            </div>
        `;
    },
    makePictureManageDiv: function(pic, dialogid) {
        var extra = "galleries_manage_picture_" + dialogid;
        var src = pic.thumbnail;
        if (this.zoom) {
            extra = "galleries_manage_picture_zoom";
            src = pic.medium;
        }
        return `
            <div class="galleries_manage_picture picture_element ${extra}" id="picture_sort_${pic.id}" type="picture" picture="${pic.id}" title="${pic.name}" t="${pic.thumbnail}" l="${pic.large}" m="${pic.medium}">
                <div class="picture_delete" picture="${pic.id}">
                    <div class="picture_delete_cursor"></div>
                </div>
                <div class="picture_content">
                    <img src="${src}" />
                </div>
            </div>
        `;
    }
}

var galleryAdmin = new GalleryAdmin();

$("body").on("click", "#admin_galleries_action_create", function() {
    var dlg = new Dialog();
    dlg.input("Neue Galerie erstellen",
        "Wählen Sie einen Titel für die Galerie",
        "Namen angeben:",
        "",
        "Neue Galerie",
        "Galerie erstellen",
        "Abbrechen",
        function(res) {
            if (res === false) {
                dlg.close();
                return;
            }
            if (res.length == 0) {
                var notify = new Notification();
                notify.info("Der Titel darf nicht leer sein");
            } else {
                galleryAdmin.create(
                    res,
                    "published",
                    function(result) {
                        dlg.close();
                        var notify = new Notification();
                        if (result === false) {
                            notify.error("Galerie konnte nicht erstellt werden");
                        } else {
                            notify.success("Galerie wurde erstellt");
                            var gallery_id = result.gallery_id;
                            galleryAdmin.navigate(0);
                            editGallery(gallery_id);
                        }
                    });
            }
        });
});

$("body").on("click", ".galleries_action_delete", function() {
    var gallery = $(this).attr("gallery");
    var gallery_name = $(this).closest(".galleries_gallery").attr("name");
    var dlg = new Dialog();
    dlg.confirm(
        "Wirklich löschen?",
        `Wollen Sie die Galerie "${gallery_name}" wirklich löschen?`,
        "Ja",
        "Nein",
        function(result) {
            if (!result) {
                dlg.close();
            } else {
                dlg.block();
                galleryAdmin.deleteGallery(gallery, function(status) {
                    dlg.unblock();
                    var notify = new Notification();
                    if (!status) {
                        notify.error("Galerie konnte nicht gelöscht werden");
                    } else {
                        notify.success("Galerie wurde gelöscht");
                        dlg.close();
                        galleryAdmin.navigate(galleryAdmin.page);
                    }
                });
            }
        }
    );
});

var editGallery = function(id) {
    var loading = new Dialog();
    loading.loading(
        "Bitte warten",
        "Die Galerie wird geladen..."
    );
    galleryAdmin.load(id, function(result) {            
        loading.close();
        if (result === false) {
            var notify = new Notification();
            notify.error("Galerie konnte nicht geladen werden");
        } else {
            var gallery = result.gallery;
            var dlg = new Dialog();

            $("body").on("click", ".galleries_manage_picture_"+dlg.id, function(e) {
                var target = $(e.target);
                if (target.is("div.picture_delete") || target.is("div.picture_delete_cursor")) {
                    return;
                }

                dlg.hide();
                var preview = new Dialog();
                var picture = $(this).attr("picture");
                var medium = $(this).attr("m");
                var title = $(this).attr("title");

                var close = function() {
                    preview.close();
                    dlg.reshow();
                }


                preview.build()
                    .addTitle("Bild-Vorschau")
                    .addHtml(`<div style="text-align: center"><img align="center" class="dialog_picture" src="${medium}" /></div><br />
                              <b>${title}</b>`)
                    .addButton("Ok", "cancel", close)
                    .onEscape(close)
                    .onEnter(close)
                    .show();
            });

            var cancel = function() {
                dlg.close();
            }

            var save = function() {
                dlg.block();
                var pictures = new Array();
                $("#galleries_picture_manager").children().each(function() {
                    pictures.push($(this).attr("picture"));
                });
                var name = dlg.getInput("name").val();
                if (name == "") {
                    var notify = new Notification();
                    notify.info("Der Name darf nicht leer sein.");
                    dlg.unblock();
                    return;
                }
                galleryAdmin.edit(gallery.id, name, pictures, function(result) {
                    dlg.unblock();
                    var notify = new Notification();
                    if (!result) {
                        notify.error("Galerie konnte nicht gespeichert werden.");
                    } else {
                        notify.success("Galerie wurde gespeichert.");
                        dlg.close();
                        galleryAdmin.navigate(galleryAdmin.page);
                    }
                });
            }

            var add = function() {
                dlg.hide();
                var addDlg = new Dialog();
                var excludes = new Array();
                $("#galleries_picture_manager").children().each(function() {
                    excludes.push($(this).attr("picture"));
                });

                addDlg.fileBrowser(
                    "Bilder hinzufügen", 
                    "Wählen Sie Bilder aus, die Sie hinzufügen möchten. Duplikate werden nicht angezeigt.", 
                    "pictures", 
                    excludes, 
                    false, 
                    async function(file, browser) {
                        if (file.type == "file") {
                            $("#galleries_picture_manager").append(
                                galleryAdmin.makePictureManageDiv(file)
                            );
                        } else if (file.type == "folder") {
                            try {
                                var folder = await $.ajax({
                                    url: "admin/folders/api/pictures/list",
                                    type: "POST",
                                    data: {
                                        id: file.id                                        
                                    }
                                });
                                var files = folder.files;
                                
                                for (var f of files) {
                                    var fid = f.id;
                                    if (!($("#galleries_picture_manager").find('.galleries_manage_picture[picture="'+fid+'"]').length)) {
                                        $("#galleries_picture_manager").append(
                                            galleryAdmin.makePictureManageDiv(f)
                                        );
                                        browser.excludes.push(fid);
                                    }
                                }
                                
                                new Notification().success("Alle direkten Bilder im Ordner werden hinzugefügt");
                            } catch (e) {
                                console.log(e);
                                new Notification().error("Fehlgeschlagen");
                            }
                        }
                    }, 
                    function() {
                        addDlg.close();
                        dlg.reshow();
                    },
                    {
                        upload: true,
                        foldercreation: true,
						folderselection: true
                    }
                );
            }

            var pictures = "";
            for (var i = 0; i < gallery.pictures.length; ++i) {
                pictures += galleryAdmin.makePictureManageDiv(gallery.pictures[i], dlg.id);
            }
            
            var icon = galleryAdmin.zoom ? "zoom_out" : "zoom_in";

            dlg.build()
                .setFullScreen(true)
                .addTitle(`Galerie bearbeiten`)
                .addHtml(`
                    <h4>Bearbeiten Sie die Galerie "${gallery.title}"</h4>
                `)
                .addInput("Name", gallery.title, "Galeriename", "name", save)
                .addHtml(`<h4>Sortieren und Entfernen Sie Bilder</h4>
                    <button class="btn waves-effect blue" id="galleries_picture_manager_size">
                        <i class="material-icons">${icon}</i>
                    </button>`)
                .addHtml(`                    
                    <div class="galleries_picture_manager" id="galleries_picture_manager">
                        ${pictures}
                    </div>
                `)
                .addButton("Speichern", "save", save)
                .addButton("Bilder hinzufügen", "add", add)
                .addButton("Abbrechen", "cancel", cancel)
                .onEscape(cancel)
                .onEnter(save)
                .show();

            $(".galleries_picture_manager").sortable();
        }
    });
}

$("body").on("click", "#galleries_picture_manager_size", function(e) {
    galleryAdmin.zoom = !galleryAdmin.zoom;
    $(".galleries_manage_picture").toggleClass("galleries_manage_picture_zoom", galleryAdmin.zoom);
    var icon = galleryAdmin.zoom ? "zoom_out" : "zoom_in";
    
    if (galleryAdmin.zoom) {
        $(".galleries_manage_picture").each(function(i, elem) {
            var m = $(elem).attr("m");
            $(elem).find("img").attr("src", m);
        });
    }
    
    $(this).find("i").html(icon);
});



$("body").on("click", ".galleries_gallery_title", function(e) {
    var target = $(e.target);
    if (target.is(".galleries_action_delete")) {
        return;
    }
    var gallery = $(this).closest(".galleries_gallery").attr("gallery");
    editGallery(gallery);    
});



$("body").on("click", ".picture_delete", function(e) {
    e.preventDefault();

    var parent = $(this).parent();
    parent.fadeTo("fast", 0, function () {
        parent.animate({
            width: 0,
            height: 0
        }, "fast", function () {
            parent.remove();
        });
    });
});


$("body").on("click", ".galleries_help", function() {
    var dlg = new Dialog();

    var close = function() {
        dlg.close();
    };

    dlg.build()
        .addTitle("Galerien: Hilfe")
        .addHtml(`
            <h4>Bilder hochladen</h4>
            Verwenden Sie den Button "Bilder verwalten" um die für Galerien verfügbaren 
            Bilder zu verwalten.

            <h4>Galerie bearbeiten</h4>
            Drücken Sie auf die Kopfleiste einer Galerie, um die Galerie zu bearbeiten.
            Hier können Sie die Galerie umbenennen, weitere Bilder hinzufügen sowie Bilder löschen
            und sortieren.

            <h4>Galerie löschen</h4>
            Verwenden Sie das Papierkorbsymbol um die Galerie zu löschen

            <h4>Ordner zu Galerie hinzufügen</h4>
            Um ganze Ordner zu einer Galerie hinzuzufügen, verwenden Sie den Button "Bilder verwalten".
            Markieren Sie die gewünschten Bilder und Ordner und klicken Sie auf "Aktionen".
            Wählen Sie anschließend die gewünschte Galerie aus.            
        `)
        .addButton("Ok", "ok", close)
        .onEscape(close)
        .onEnter(close)
        .show();
});


$("body").on("click", ".admin_galleries_action_next", function() {
    if ($(this).hasClass("disabled")) {
        return;
    }
    galleryAdmin.navigate(galleryAdmin.page + 1);
});

$("body").on("click", ".admin_galleries_action_prev", function() {
    if ($(this).hasClass("disabled")) {
        return;
    }
    galleryAdmin.navigate(galleryAdmin.page - 1);
});

$("body").on("click", ".admin_galleries_action_page", function() {
    if ($(this).hasClass("active")) {
        return;
    }
    galleryAdmin.navigate($(this).data("page"));
});